
.ant-input-affix-wrapper {
    padding: 1%;
}

.product-list-container .ant-page-header-heading {
    /* background-color: #2D94C6; */
    /* padding:20px;
    border-radius: 10px; */
}

.cardStyle {
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    border-color: #F27905;
    min-height: 20vh;
}

.product-container {
    background-color: #ECF0F2;
    margin-top: 30px;
    padding: 2%;
    border-radius: 10px;
}

.product-container::before {
    background-image: url('../../images/grayBackgroundImage.svg');
}

.view-link:hover{
    opacity: 0.5;
    cursor: pointer;
}
.shopping-icon-link:hover{
    opacity: 0.5;
    cursor: pointer;
}
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  overflow: hidden;
  background: transparent;
  border: 0px;
  
  
}