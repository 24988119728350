
.welcome-text {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  font-family: "poppins-bold";
  font-size: 4vmin;
  text-align: center;
  font-weight: 600;
}
  
.home-text {
  flex:1;
  justify-content: center;
  font-family: "Poppins-medium";
  text-align: center;
  color: #6c757d;
  margin-top: -2.5vh;
  font-size: 15px;
  margin-bottom: 4vh;
}

.otp-disabled-button {
  display: flex;
  justify-content: center;
  background-color: gray !important;
  color: #ffffff !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  margin-top: 0.5vh;
  font-weight: bold;
  font-family: "Poppins-medium";
  border-color: #f27906 !important;
}

.otp-button {
  display: flex;
  justify-content: center;
  background-color: #f27906 !important;
  color: #ffffff !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  margin-top: 0.5vh;
  font-weight: bold;
  font-family: "Poppins-medium";
  border-color: #f27906 !important;
}

.otp-button:hover {
  background-color: #ff8d22 !important;
  color: #ffffff !important;
  border-color: #ff8d22 !important;
}

.otp-button:focus{
  background-color: #ff8d22 !important;
  color: #ffffff !important;
  border-color: #ff8d22 !important;
}

.policy-text {
  flex:1;
  font-family: "Poppins-medium";
}

