.headerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12%; 
  margin-bottom: -70px;
  background-color: #fff;
  height: 100px; 
  box-shadow: 0px 2px 3px rgba(54, 53, 53, 0.01);
}

.navbar-logo {
  padding: 0;
  margin: 0;
}

.menu-items {
  display: flex;
  gap: 40px; 
  align-items: center;
}

.ham-menu-items {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center vertically */
  gap: 40px;
  justify-content: center;
  margin: auto;
  padding: auto;
}


.menu-item {
  margin: 0;
}

/* Media query for screens up to 1200px width */
@media screen and (max-width: 1200px) {
  .menu-items {
    gap: 30px; 
  }
  .headerStyle{
    padding: 10px 10%;
  }
}
@media screen and (max-width: 1100px) {
  .menu-items {
    gap: 20px; 
  }
  .headerStyle{
    padding: 10px 8%;
  }
}
@media screen and (max-width: 951ppxx) {

  .headerStyle{
    padding: 10px 2%;
  }
}

/* Media query for smaller screens */
@media screen and (min-width: 942px) {
 .hamburger{
  display: none;
 }
}

@media screen and (max-width: 942px) {
  .menu-item {
    display: none;
  }
  .navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-right: 7%;
    text-align: center;
  }
}