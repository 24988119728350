.heading {
  text-transform: uppercase;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: "Anton", sans-serif;
  letter-spacing: 0.1rem;
  font-size: 2rem;
  font-weight: 500;
}

.serviceheading {
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  height: 150px;
  font-family: "Anton", sans-serif;
  letter-spacing: 0.1rem;
  font-size: 2.5rem;
  font-weight: 500;
}

.image {
  width: 100%;
  height: 400px;
}

.serviceimage {
  width: 100%;
  height: 400px;
}

serviceHeadings {
  font-family: "Poppins-SemiBold";
}

serviceimageBox {
  margin: auto;
}

image2 {
  width: 90%;
  height: 400px;
}

serviceimage2 {
  width: 90%;
  height: 400px;
}

serviceimageBox2 {
  margin: auto;
}

.textDiv1 {
  padding: 0px 15px 30px 15px;
  margin: auto;
  grid-area: T1;
}

.textDiv2 {
  padding: 0px 15px 30px 15px;
  margin: auto;
  grid-area: T2;
}

imageDiv1 {
  grid-area: I1;
}

imageDiv2 {
  grid-area: I2;
}

#SI1 {
  grid-area: SI1;
}

#SI2 {
  grid-area: SI2;
}

#SI3 {
  grid-area: SI3;
}

#SI4 {
  grid-area: SI4;
}

#SI5 {
  grid-area: SI5;
}

#SI6 {
  grid-area: SI6;
}

#ST1 {
  grid-area: ST1;
}

#ST2 {
  grid-area: ST2;
}

#ST3 {
  grid-area: ST3;
}

#ST4 {
  grid-area: ST4;
}

#ST5 {
  grid-area: ST5;
}

#ST6 {
  grid-area: ST6;
}

.firstDiv {
  width: 100%;
  margin: auto;
  padding: auto;
  display: grid;
  grid-template-areas:
    "I1 T1"
    "T2 I2";
  gap: 20px;
  justify-content: space-around;
  margin-bottom: 20px;
}

.mainServiceDiv {
  width: 100%;
  margin: auto;
  padding: auto;
  display: grid;
  grid-template-areas:
    "SI1 ST1"
    "ST2 SI2"
    "SI3 ST3"
    "ST4 SI4"
    "SI5 ST5"
    "ST6 SI6";
  gap: 20px;
  justify-content: space-around;
  margin-bottom: 20px;
}

.textDiv {
  padding: 0px 15px 30px 15px;
  margin: auto;
}

.heading_h3 {
  font-family: "Anton", sans-serif;
  letter-spacing: 0.1rem;
  font-size: 1.7rem;
  font-weight: 550;
  margin-top: 45px;
  margin-bottom: 1.5rem;
}

.text {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 1.5rem;
  font-size: 16px;
  color: #939393;
  text-align: justify;
  line-height: 1.8rem;
}

.button {
  color: #ffffff;
  background-color: #fd5f00;
  padding: 6px 16px;
  border: none;
  border-radius: 0.3rem;
  font-size: 16px;
  cursor: pointer;
}

.featured-tests {
  background-color: #fff8f2;
  padding-left: 15%;
  padding-bottom: 7%;
}

.featured-test-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.featured-test {
  flex: 0 0 48%;
  margin-bottom: 15px;
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.featured-test h2 {
  font-family: "Mont-Book";
  font-size: 24px;
  margin-bottom: 5px;
}

.featured-test p {
  font-family: "Mont-Regular";
  font-size: 16px;
  max-width: 100%;
  line-height: 1.5;
}

@media (max-width: 1005px) {
  .featured-test {
    flex: 0 0 100%;
    margin-bottom: 20px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 650px) {
  .featured-test {
    flex: 0 0 100%;
    margin-bottom: 20px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    border-width: 1px;
  }
}

@media (max-width: 1005px) {
  .featured-test-list {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .featured-test-list {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 950px) {
  .serviceimage {
    width: 100%;
    height: 350px;
  }

  .serviceimage2 {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 950px) {
  .serviceimage {
    width: 100%;
    height: 350px;
  }

  .serviceimage2 {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 688px) {
  .serviceimage {
    width: 100%;
    height: 350px;
  }

  .serviceimage2 {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 641px) {
  .serviceimage {
    width: 100%;
    height: 320px;
  }

  .serviceimage2 {
    width: 100%;
    height: 320px;
  }
}

@media (max-width: 600px) {
  .serviceimage {
    width: 100%;
    height: 270px;
  }

  .serviceimage2 {
    width: 100%;
    height: 270px;
  }
}

@media (max-width: 525px) {
  .serviceimage {
    width: 100%;
    height: 250px;
  }

  .serviceimage2 {
    width: 100%;
    height: 250px;
  }
}

@media (max-width: 495px) {
  .serviceimage {
    width: 100%;
    height: 200px;
  }

  .serviceimage2 {
    width: 100%;
    height: 200px;
  }
}

@media (max-width: 339px) {
  .serviceimage {
    width: 100%;
    height: 150px;
  }

  .serviceimage2 {
    width: 100%;
    height: 150px;
  }
}
