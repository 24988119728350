@import "antd/dist/antd.css";

@font-face {
  font-family: Poppins-Black;
  src: url(./font/Poppins-Black.ttf);
}

@font-face {
  font-family: Poppins-Thin;
  src: url(./font/Poppins-Thin.ttf);
}

@font-face {
  font-family: Mont-Book;
  src: url(./font/Mont-Book.ttf);
}

@font-face {
  font-family: Mont-Light;
  src: url(./font/Mont-Light.ttf);
}
@font-face {
  font-family: Mont-Regular;
  src: url(./font/Mont-Regular.ttf);
}
@font-face {
  font-family: Mont-SemiBold;
  src: url(./font/Mont-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(./font/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(./font/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-Light;
  src: url(./font/Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(./font/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(./font/Poppins-SemiBold.ttf);
}

body{
  overflow-x: hidden;
}
.option-card {
  background-color: #2D94C6;
  height: 33%;
  width: 50%;
}

.icons-image {
  margin-left: 12vw;
  height: 64%;
  width: 34%;
}

.service-image {
  margin-right: 20%;
  height: 50%;
  width: 50%;
  align-items: center;
  padding-left: 100%;
}

.arrow-image {
  width: 34%;
  height: 53%;
  margin-left: 10vw;
  margin-top: 1vh;
}

.test-image {
  width: 50%;
  height: 50%;
  opacity: 100%;
  align-items: center;
}

.info-image {
  width: 16%;
  height: 16%;
  margin-left: 15vw;
}

.input-block {
  height: 36px;
  width: 3000px;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  display: flex;
  align-items: space-between;
}

.input-ele {
  border: none;
  width: 290px;
}

.circle-button {
  background-color: #F27906;
  color: #ffffff;
  border: none;
  margin-left: 24vw;
  width: 3em;
  height: 3em;
}
.pageTitle {
  font-family: Poppins-Bold;
}

.ant-menu {
  background: transparent;
}

.my-select-container .ant-select .ant-select-selector {
  position: relative;
  background-color: #ecf0f2;
  border: 1px solid #cccccc;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 15px;
}

.ant-page-header-heading {
  align-items: center;
}

.no-margin {
  margin: 0;
}

.white-text {
  color: white
}

.padding-5 {
  padding: 5px;
}

.poppins-regular {
  font-family: Poppins-Regular;
}

.poppins-bold {
  font-family: Poppins-Bold;
}

.poppins-semibold {
  font-family: Poppins-SemiBold;
}

.poppins-light {
  font-family: Poppins-Light;
}

.poppins-medium {
  font-family: Poppins-Medium;
}

.background {
  background-image: transparent url('images/grayBackgroundImage.svg') 0% 0% no-repeat padding-box;
  opacity: 1;
}

.background::before {
  content: "";
  background-image: transparent url('images/grayBackgroundImage.svg') 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;

}

.card-header {
  font-family: Poppins-semiBold;
  color: #104169;
}

.pageheader-background-color {
  background-color: #2D94C6;
}

.header-container .ant-page-header-heading {
  background-color: #2D94C6;
  padding: 20px;
  border-radius: 10px;
}

.page-header-title {
  color: white;
  font-size: larger;
}

.card-title {
  font-family: Poppins-semiBold;
  color: #330033;
}

.cart-card-style {
  border-radius: 14px;
  border-color: #D6DBE0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
}

.card-content {
  font-family: Poppins-Regular;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  margin:auto;
  padding:auto;
  background-color:white;
  border:none;
  color: #330033;
}
.card-content:hover {
  background-color: #fff; 
  color: #330033; 
  border:none;
  cursor: pointer;
}
.page-header-style {
  width: 98%;
  background: #2D94C6;
  border-radius: 5px;
  margin-left: 1%;
  padding: 25px;
  padding-bottom: 35px;
  font-family: Poppins-SemiBold;
}

.body-style {
  width: 100%;
  padding: 1.5%;
  background-color: #ECF0F2;
  border-radius: 10px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-a-test-button {
  display: flex;
  justify-content: center;
  background-color: #2C95C5 !important;
  color: #ffffff !important;
  padding: 25px;
  margin:auto 3%;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Poppins-medium";
  text-align: center;
  align-items: center;
  border-color: #2C95C5 !important;
} 
.book-a-test-button:hover {
  display: flex;
  justify-content: center;
  background-color: #2C95C5 !important;
  color: #ffffff !important;
  padding: 25px;
  margin:auto 0;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Poppins-medium";
  text-align: center;
  align-items: center;
  border-color: #2C95C5 !important;
} 
.comman-button {
  display: flex;
  justify-content: center;
  background-color: #f27906 !important;
  color: #ffffff !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  font-weight: bold;
  font-family: "Poppins-medium";
  border-color: #f27906 !important;
}

.comman-button:hover {
  background-color: #ff8d22 !important;
  color: #ffffff !important;
  border-color: #ff8d22 !important;
}

.comman-button:focus {
  background-color: #ff8d22 !important;
  color: #ffffff !important;
  border-color: #ff8d22 !important;
}

.footer-background {
  padding: 0;
  background: transparent url('./images/footer-background.png');
}


.cart-footer-background {
  display: none;
}


.title-color {
  color: #330033;

}

.ant-list {
  overflow-x: hidden;
  overflow-y: scroll;
  height: auto;
}

body {
  width: 100%;

}
.cart-card-style-div{
  margin-left: 3vw;
  height: 58vh;
}
.cart-time-style-div{
  height: 58vh;
}

@media all and (max-width: 767px) {
  .cart-card-style-div{
    margin-left: 3vw;
    height: auto;
    padding-bottom: 10px;
  }
}