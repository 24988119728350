.home-about-container {
    display: flex;
    flex-direction: column;
    margin-right: 10%;
    margin-left: 10%;
    font-family: Mont-Regular;
    opacity: 0.9;
    margin-top: 80px;
    margin-bottom: 30px;
  }
  
  .section-title {
    color: #399ac8;
    font-size: 20px;
    font-family: Mont-SemiBold;
    margin-bottom: 5px;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }
  
  .left-content {
    color: #131313;
    font-size: 29px;
    width: 38%;
    line-height: 1.45;
  }
  
  .right-content {
    color: #131313;
    font-size: 17px;
    width: 55%;
    margin: auto;
  }
  
  .read-more {
    width: 170px;
    height: 52px;
    border-radius: 30px;
    border: 2px solid #399ac8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #399ac8;
    margin-top: 40px;
  }
  .read-more-about {
    width: 170px;
    height: 52px;
    border-radius: 30px;
    border: 2px solid #399ac8;
    display: flex;
    margin:auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #399ac8;
    margin-top: 40px;
  }

  .read-more:hover{
    cursor: pointer;
  }
  
  .read-more-text {
    font-family: Mont-SemiBold;
    display: inline-block; 
    vertical-align: middle;
  }
  
  .arrow-icon {
    width: 10px;
    height: 10px;
    margin-left: 15px;
  }
 
 /* CSS for AboutNumbers component */

.about-numbers-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-right: 10%;
  margin-left: 10%; */
  font-family: Mont-Regular;
  opacity: 0.9;
  margin-top: 80px;
  margin-bottom: 30px;
}

.about-number-item {
  width: calc(25% - 20px); 
  display: flex;
  flex-direction: column;
  border-left: 2px solid #f48a25;
  padding-left: 20px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.about-number {
  color: #399ac8;
  font-family: "Mont-Light";
  font-size: 54px;
}

.about-number-text {
  font-size: 18px;
  font-family: "Mont-SemiBold";
}

/* Three boxes in one row for medium screens (e.g., tablets) */
@media screen and (max-width: 768px) {
  .about-number-item {
    width: calc(33.333% - 20px); /* Three boxes in one row with spacing */
  }
}

/* Two boxes in one row for small screens (e.g., smartphones) */
@media screen and (max-width: 480px) {
  .about-number-item {
    width: calc(50% - 20px); /* Two boxes in one row with spacing */
  }
}

/* One box in one row for extra-small screens (e.g., smaller smartphones) */
@media screen and (max-width: 320px) {
  .about-number-item {
    width: calc(100% - 20px); /* One box in one row with spacing */
  }
}
