.download-container .ant-btn:hover {
    background: #87d068;
    border-color: #87d068;
    color: white;
}


.report-content-container {
    padding: 3%;
    display: flex;
    min-width: 120px;
    align-items: center; 
    border-radius: 5px;        
}

.report-content-alignment {
    display: flex; 
    flex-direction: column; 
    margin-left: 15px;
}

.report-card-style {
  border-color: #D6DBE0;
  margin-top: 20px;
  border-radius: 20px;
  overflow: hidden;
  padding: 1%;
}

.scheduled-time{
    padding-left: "9px";
    padding-right: "9px";
}


@media only screen and (min-width: 320px) and (max-width: 480px) {
    .report-card-style{
        border-radius: 5px;
        padding: 5%;
    }

    .download-container .repeat-order-btn {
        margin-bottom: 10px;
    }

    .branch-search {
        min-width: 50vw;
        max-width: 74vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .report-card-style{
        border-radius: 5px;
        padding: 5%;
    }
    
}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .report-card-style{
        border-radius: 10px;
        padding: 5%;
    }
    
    
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .scheduled-time{
        padding-left: "2px";
        padding-right: "2px";
    }
    
}


