/* Banner.css */
.banner {
    background-size: cover;
    background-position: center;
    height: 550px;
    position: relative;
  }
  
  .banner-text {
    width: 450px;
    font-size: 35px;
    font-family: "Mont-Book";
    position: absolute;
    right: 50%;
    top: 40%;
    text-align: right; /* Align the text to the right */
  }
  
  .top-image {
    vertical-align: top;
  }
  
  .right-text {
    float: right; /* This will align the text to the right side of the inner div */
    margin-right: 15px; /* Adjust the margin to control the spacing */
  }
  
  .banner-text-highlight {
    color: #399ac8;
    font-family: "Mont-SemiBold";
    font-weight: 900;
  }
  
@media only screen and (max-width: 653px) {
    .banner-container {
      min-width: 100%;
      min-height: 100vw !important;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5%;
    }
  }
  
  @media screen and (max-width: 923px) {
    .banner-text {
      position: absolute; /* or position: relative */
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  
  @media only screen and (min-width: 653px) and (max-width: 780px) {
    .banner-container {
      min-width: 100%;
      background-image: url("/public/images/Banner-2.jpg") !important;
      min-height: 40vw !important;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5%;
    }
  }
  
  @media only screen and (min-width: 1115px) and (max-width: 70069px) {
    .banner-container {
      background-image: url("/public/images/Banner-2.jpg") !important;
      min-height: 70vh !important;
    }
  }
  
  @media only screen and (min-width: 780px) and (max-width: 1115px) {
    .banner-container {
      width: 100%;
      min-height: 55vh !important;
      background-image: url("/public/images/Banner-2.jpg") !important;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      padding: 5%;
    }
  }
  
  @media all and (max-width: 1118px) {
    .gridOfServices {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      width: 80%; /* Remove the double quotes around the value */
      margin: auto; /* Remove the double quotes around the value */
      justify-content: center;
    }
  }
  
  @media all and (max-width: 999px) {
    .gridOfServices {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      width: 80%; /* Remove the double quotes around the value */
      margin: auto; /* Remove the double quotes around the value */
      justify-content: center;
    }
  }
  
  @media only screen and (max-width: 683px) {
    .gridOfServices {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      width: 80%; /* Remove the double quotes around the value */
      margin: auto; /* Remove the double quotes around the value */
      justify-content: center;
    }
  }