.next-button {
  display: flex;
  justify-content: center;
  background-color: #f27906 !important;
  color: #ffffff !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  margin-top: 0.5vh;
  font-weight: bold;
  font-family: 'Poppins-medium';
  border-color: #f27906 !important;
}

.next-button:hover {
  display: flex;
  background-color: #ff8d22 !important;
  color: #ffffff !important;
  border-color: #ff8d22 !important;
}

.next-button:focus {
  display: flex;
  background-color: #ff8d22 !important;
  color: #ffffff !important;
  border-color: #ff8d22 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: transparent;
  transition: background-color 0.3s;
  content: '';
}

.ant-select-selection {
  background-color: green;
}

.ant-card-body {
  padding: 0;
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #ecf0f2;
  border: 1px solid #cccccc;
  
  height: 100% !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
} */

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
  display: flex;
  align-items: center;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
  display: flex;
  align-items: center;
}