.branch-search {
    min-width: 30vw;
    max-width: 34vw;
}

.phone-icon:hover{
    opacity: 1;
    cursor: pointer;
}
.branch-search-container .ant-select .ant-select-selector { 
    border-radius: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .branch-search {
        min-width: 50vw;
        max-width: 74vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .branch-search {
        min-width: 50vw;
        max-width: 74vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .branch-search {
        min-width: 50vw;
        max-width: 74vw;
    }
}
