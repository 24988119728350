.footer-component-container {     
    background-color: #333333;
    padding: 4.8vw;  
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hover-pointer {
    cursor: pointer;
  }

.footer-link {
    opacity: .9;
    font-size: 12px;
}

.footer-link-heading{
    color:#f27905;
}

.footer-link:hover {
    opacity: 1;
    cursor: pointer;
}

.footer-component-container * {
    color: white;
}


.dividerStyle {
    background-color: gray;
    margin: 10px 0px;
}

h2 {
    font-family: Poppins-Bold;
}

h3,h4 {
    font-family: Poppins-Regular;
}

h1 {
    font-size: 1.2em;
    
}