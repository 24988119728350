.member-details-container {
  display: flex;
  flex-direction: row;
}

.gender-btn {
  display: flex;
  width: 30%;
  font-family: Poppins-regular;
  background-color: #ecf0f2;
  color: #6c757d;
  border: 1px solid #cccccc;
}

.memberCard{
  height:48vh;
}
.form-item-no-margin .ant-form-item-control {
  display: flex;
  align-items: center;
}
.ant-form-item-explain {
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 15px;
  }

@media screen and ( max-height: 500px) {
  .memberCard{
    height:35vh;
  }
}

