/* @media all and (max-width: 950px) and (min-width:851px) {
    .BookTestModal .ant-modal-content {
        width: 550px;
        margin: auto;
    }
}*/

@media all and (max-width: 850px) and (min-width:651px) {
    .BookTestModal .ant-modal-content {
        width: 500px;
        margin: auto;
    }
} 

@media all and (max-width: 650px) and (min-width:501px) {
    .BookTestModal .ant-modal-content {
        width: 80%;
        margin: auto;
    }
}

@media all and (max-width: 500px) {
    .BookTestModal .ant-modal-content {
        width: 95%;
        margin: auto;
    }
}