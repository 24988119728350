.site-layout .site-layout-background {
  background: rgb(216, 213, 221);
}


.ant-page-header .ant-page-header-heading {
  margin-bottom: -15px;
}

.ant-menu.ant-menu-light {
  color: black;
  font-weight: normal;
}

.ant-menu.ant-menu-light .ant-menu-item-selected.customclass {
  color: rgb(69, 49, 28);
  font-weight: bold;
  border-bottom: 2px solid rgb(69, 49, 28);
}

.ant-carousel .slick-dots li {
  background: gray;
}

.ant-steps-item-process .ant-steps-item-tail::after {
  background: rgb(216, 213, 221) !important;
}

.ant-tabs-nav-wrap {
  margin-left: 9vw;
}

.ant-tabs-tab {
  border: #2d94c6 solid !important;
  border-radius: 10px !important;
  margin-left: 1vw !important;
  height: 7vh !important;
}

.ant-tabs-tab-active {
  background: skyblue !important;
  color: white !important;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: plum;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: "10px" !important;
}

.ant-form {
  margin-left: "2vh";
}

.hide-arrow-buttons .ant-input-number-handler-wrap {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;